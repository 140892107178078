import axios from 'axios';

const apiUrl = 'https://portal.abovedigital.co/api';

export const fetchAirtableData = async () => {
  try {
    const response = await axios.get(`${apiUrl}/airtable-data`);
    return response.data;
  } catch (error) {
    console.error('Error fetching data from backend:', error);
    throw error;
  }
};